import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { Layout, HardwareTable, AffiliateDisclosure, AutoCompleteTagInput } from '../components/common'
import { MetaData } from '../components/common/meta'
import { Pane } from 'evergreen-ui';
import { SIGN_UP_BONUS_HTML, currentMonth, currentMonthShort } from '../utils/constants';
import Img from 'gatsby-image'
import hardwareWalletsInfo from '../data/hardwareWalletsInfo.json';

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/

const HardwareWalletsPage = (props) => {
    const { pageContext, location } = props;
    let title = `Best Crypto Hardware / Cold Wallets of ${currentMonth} 2025`;
    let excerpt = `The best crypto hardware wallets / cold wallets of ${currentMonth} 2025. Comparison of the top non-custodial wallets to store your BTC, ETH, and other cryptocurrencies.`;

    let filteredWallets;
    if (pageContext.title) {
        title = pageContext.title;
        excerpt = `Comparison of ${pageContext.title}. Find the best crypto hardware wallet / cold wallet to store your cryptocurrencies.`
        filteredWallets = hardwareWalletsInfo.filter(wallet => pageContext.title.includes(wallet.walletName));
    } else {
        filteredWallets = [...hardwareWalletsInfo];
    }
    const [wallets, setWallets] = useState(filteredWallets)


    const page = { meta_title: title, title, excerpt, description: excerpt };
    const newData = { ghostPage: page };
    const metaImage = 'https://www.cefirates.com/images/hardware-wallets/best-crypto-hardware-cold-wallets.png'

    return (
        <>
            <MetaData
                data={newData}
                location={location}
                type="website"
                image={metaImage}
            />
            <Helmet>
                <style type="text/css">{`${page.codeinjection_styles}`}</style>
            </Helmet>
            <Layout location={location}>
                <div className="container">
                    <article className="content">
                        <figure className="post-feature-image">
                            <Pane marginX="auto" maxWidth={720}>
                            <img src="/images/hardware-wallets/best-crypto-hardware-cold-wallets.png" />
                            </Pane>
                        </figure>
                        <Pane className="content-title image-heading-container">
                            <h1 className="image-heading">{title}</h1>
                        </Pane>
                        <section className="content-body load-external-scripts post-card-excerpt">{excerpt}</section>
                        <AutoCompleteTagInput wallets={wallets} setWallets={setWallets} />
                        <HardwareTable wallets={wallets} />
                        <AffiliateDisclosure />
                    </article>
                </div>
            </Layout>
        </>
    )
}

export default HardwareWalletsPage;

// export const postQuery = graphql`
//     query($slug: String!) {
//         ghostPage(slug: { eq: $slug }) {
//             ...GhostPageFields
//         }
//     }
// `
