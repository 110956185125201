import React from 'react'
import { graphql, navigate } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Pane, Button, ArrowLeftIcon } from 'evergreen-ui';
import { currentMonth, currentMonthShort } from '../utils/constants';
import { slugToWithdrawalFeesMap } from '../data/withdrawalFeesInfo';
import { Layout, Link, AffiliateDisclosure, WithdrawalFeesTable } from '../components/common'
import { MetaData } from '../components/common/meta'
import { titleCase } from 'title-case';


/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const WithdrawalFeesPage = ({ data, location }) => {
    const page = data.ghostPage;
    const title = page.title + ` of ${currentMonthShort} 2025`;
    const { withdrawalFees, bonusHTML, name: platform, bonus, customExcerpt, customDescription, versusPath, slug } = slugToWithdrawalFeesMap[data.ghostPage.slug];
    const excerpt = customExcerpt || `The table below shows the ${platform} withdrawal fees of ${currentMonth} 2025, for each supported cryptocurrency and stablecoin:`;
    const description = customDescription || `${platform} withdrawal fees of ${currentMonthShort} 2025, for each supported cryptocurrency and stablecoin. See the costs involved in withdrawing your crypto from ${platform}.`;

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
                title={title}
                description={description}
            />
            <Helmet>
                <style type="text/css">{`${page.codeinjection_styles}`}</style>
            </Helmet>
            <Layout location={location}>
                <div className="container">
                    <article className="content">
                        <section className="post-full-content">
                            <Link to="/withdrawal-fees/"><ArrowLeftIcon marginBottom={4} /> All Withdrawal Fees By Platform</Link>
                        </section>
                        { page.feature_image ?
                            <figure className="post-feature-image">
                                <img width="2" height="1" src={ page.feature_image } alt={`${page.title} Logo`} />
                            </figure> : null }
                            <section className="post-full-content">
                                <h1 className="content-title">{title}</h1>
                                {withdrawalFees && <p className="content-body load-external-scripts">{excerpt}</p>}
                                {withdrawalFees && <WithdrawalFeesTable withdrawalFees={withdrawalFees} />}
                                {/* The main page content */ }
                                <section
                                    className="content-body load-external-scripts"
                                    dangerouslySetInnerHTML={{ __html: page.html }}
                                />
                                
                                <hr/>
                                <div className="buttons-group">
                                    <Link to="/bitcoin-btc-interest/">
                                        <Button marginRight={12} marginBottom={12} size="large" appearance="primary">
                                            Compare Best Rates by Cryptocurrency
                                        </Button>
                                    </Link>
                                    <Link to="/withdrawal-fees/">
                                        <Button marginRight={12} marginBottom={12} size="large">
                                            All Withdrawal Fees by Platform
                                        </Button>
                                    </Link>
                                </div>
                                <hr/>
                                {bonusHTML && bonus && <>
                                    <section className="content-body">
                                        <h2 id="sign-up-bonuses">{platform} Sign Up Bonus - {titleCase(bonus)}</h2>
                                    </section>
                                    <section
                                        className="content-body load-external-scripts"
                                        dangerouslySetInnerHTML={{ __html: bonusHTML }}
                                    />
                                </>}
                                <section className="content-body">
                                    <h2>{platform} Interest Rates</h2>
                                    <p>
                                        In addition to withdrawal fees, you can check out the <Link to={slug}>current {platform} interest rates & recent changes</Link>. You can also see how <Link to={versusPath}>{platform} rates compare head to head with alternatives</Link>.
                                    </p>
                                </section>
                                <AffiliateDisclosure />
                            </section>
                    </article>
                </div>
            </Layout>
        </>
    )
}

export default WithdrawalFeesPage

export const postQuery = graphql`
    query($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            ...GhostPageFields
        }
    }
`
