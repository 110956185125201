import React from 'react';
import { CRYPTO_COM_BONUS_HTML } from '../utils/constants';
import { ratesInt as newInt, ratesUS as newUS, cryptoComDateEffective } from './crypto-com-rates/1-1-25';
import { ratesInt as oldInt, ratesUS as oldUS } from './crypto-com-rates/11-20-24';

export const cryptoComAdditionalInfoInt = 'max total assets that can earn interest is $500,000 (up to $2,000,000 based on how much CRO staked)';
const cryptoComAdditionalInfoUS = cryptoComAdditionalInfoInt + '; unavailable in NY';
export const cryptoComAdditionalInfoUSTX = cryptoComAdditionalInfoInt + '; unavailable in TX';
export const cryptoComCantWithdraw = 'cannot be deposited or withdrawn, only bought or sold through the Crypto.com App';

export const cryptoComRowInfoUS = {
    name: 'Crypto.com',
    additionalInfo: cryptoComAdditionalInfoUS,
    icon: '/images/crypto-com-logo.svg',
    order: 7,
    bonus: '$25 in CRO',
    bonusLink: 'crypto-com-bonus',
    referralSlug: 'crypto-com-app-referral',
};

const cryptoComUSDCWithdraw = <div>10 USDC (ERC20)<br />1.2 USDC (SOL)<br />0.8 USDC (Polygon)<br />0.18 USDC (Cronos)<br />1 USDC (Arbitrum One)<br />1.28 USDC (Optimism)<br />1 USDC (Stellar Lumens)<br />4.7 USDC (AVAXC)<br />0.72 USDC (Base)<br />0.06 USDC (ZkSync Era)<br />0.66 USDC (Near)</div>;
const cryptoComUSDTWithdraw = <div>10 USDT (ERC20)<br />0.18 USDT (Cronos)<br />0.8 USDT (Polygon)<br />1.25 USDT (Optimism)<br/>1.2 USDT (SOL)</div>;
const cryptoComBTCWithdraw = '0.0006 BTC';
const cryptoComETHWithdraw = <div>0.005 ETH (ERC20)<br />0.001 ETH (Arbitrum One)<br />0.003 ETH (Arbitrum Nova)<br />0.0004 ETH (ZkSync Era)<br />0.0001 ETH (EthereumPoW)<br />0.00015 ETH (AVAXC)<br />0.00008 ETH (Cronos)<br />0.000035 ETH (Polygon)<br />0.0004 ETH (OP)<br />0.00012 ETH (Starknet)<br />0.000026 ETH (Cronos zkEVM)</div>;
const cryptoComDOTWithdraw = <div>0.1 DOT (DOT)<br />0.0001 DOT (Cronos POS)<br />0.025 DOT (Cronos)</div>;
const cryptoComMATICWithdraw = <div>25 MATIC (ERC20)<br />0.02 MATIC (Polygon)<br />0.2 MATIC (Cronos)</div>;
const cryptoComADAWithdraw = <div>0.5 ADA (Cardano)<br />0.0001 ADA (Cronos POS)<br />0.3 ADA (Cronos)</div>;
const cryptoComDAIWithdraw = <div>10 DAI (ERC20)<br />0.18 DAI (Cronos)</div>;
const cryptoComAVAXWithdraw = <div>0.005 AVAX (AVAX)<br />0.09 AVAX (AVAXC)<br />0.0012 AVAX (Cronos)</div>;
const cryptoComBNBWithdraw = <div>0.0005 BNB (BEP2)<br />0.005 BNB (BSC)</div>;
const cryptoComATOMWithdraw = <div>0.0055 ATOM (ATOM)<br />0.02 ATOM (Cronos)</div>;
const cryptoComSOLWithdraw = <div>0.008 SOL (SOL)<br />0.002 SOL (Cronos)</div>;
const CROWithdraw = <div>1 CRO (Cronos)<br />0.001 CRO (Cronos POS)<br />200 CRO (ERC20)</div>;
const ALGOWithdraw = <div>0.5 ALGO (Algorand)<br />0.9 ALGO (Cronos)</div>;
const VETWithdraw = <div>0.05 VET (VeChain)<br />4.9 VET (Cronos)</div>;
const EGLDWithdraw = <div>0.01 EGLD (EGLD)<br />0.003 EGLD (Cronos)</div>;
const ONEWithdraw = <div>0.0008 ONE (ONE)<br />11.6 ONE (Cronos)</div>;
const NEARWithdraw = <div>0.1 NEAR (NEAR)<br />0.1 NEAR (Cronos)</div>;
const ZILWithdraw = <div>1.2 ZIL (ZIL)<br />7.8 ZIL (Cronos)</div>;
const FTMWithdraw = <div>0.1 FTM (Fantom)<br />0.4 FTM (Cronos)</div>;
const STXWithdraw = <div>0.1 STX (STX)<br />0.4 STX (Cronos)</div>;

export const cryptoComWithdrawals = {
    BTC: cryptoComBTCWithdraw,
    ETH: cryptoComETHWithdraw,
    DOT: cryptoComDOTWithdraw,
    MATIC: cryptoComMATICWithdraw,
    ADA: cryptoComADAWithdraw,
    DAI: cryptoComDAIWithdraw,
    USDC: cryptoComUSDCWithdraw,
    USDT: cryptoComUSDTWithdraw,
    SOL: cryptoComSOLWithdraw,
    AVAX: cryptoComAVAXWithdraw,
    BNB: cryptoComBNBWithdraw,
    ATOM: cryptoComATOMWithdraw,
    CRO: CROWithdraw,
    EGLD: EGLDWithdraw,
    ONE: ONEWithdraw,
    ALGO: ALGOWithdraw,
    VET: VETWithdraw,
    NEAR: NEARWithdraw,
    ZIL: ZILWithdraw,
    FTM: FTMWithdraw,
    APT: '0.009 APT',
    STX: STXWithdraw,
};

export const makeCryptoComMaxRateInfo = (rawMaxRate) => {
    const split = rawMaxRate.split(')');
    const maxRate = split.join(') APR,');
    return (
        <div>
            {maxRate}
            <br /><br />
            highest rate requires locking asset for 3 months, and staking (holding for 6 months) $40,000 worth of CRO    
        </div>
    );
}

export const cryptoComRates = {
    oldUS,
    oldInt,
    newUS,
    newInt,
    platform: 'Crypto.com',
    bonusHTML: CRYPTO_COM_BONUS_HTML,
    dateEffectiveUS: cryptoComDateEffective,
    dateEffectiveInt: cryptoComDateEffective,
    APR: true,
    maxInfoInt: 'the max APR of each coin (except CRO) requires locking asset for 3 months, and staking (holding for 6 months) $40,000 worth of CRO',
    maxInfoUS: 'the max APR of each coin (except CRO) requires locking asset for 3 months, and staking (holding for 6 months) $40,000 worth of CRO',
    key: 'cryptoCom',
};
